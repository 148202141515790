/* about section */
.section.about{
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 60px #8D8D8D29;
    opacity: 1;
    display:flex;
    flex-direction: row-reverse;
    color: #1e1e1e;
    justify-content: space-between;
    direction: rtl;
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  
  .section.about .image-container{
    max-height: 100%;
    max-width: 758px;
    overflow: hidden;
    /* margin-top: 100px; */
  
  }
  
  .section.about .about-title{
    padding: 5% 10%;
    display: flex;
    flex-direction: column;
    /* flex: 1; */
    width: 40%;
  }
  
  .section.about img{
    height: 100%;
    width: auto;
  }
  
  .section.about h2{
    font-size: 46px;
    line-height: 86px;
  }
  
  .section.about p{
    font-size: 24px;
    line-height: 44px;
    /* direction: rtl; */
  }
  
  .section.about h4{
    font-weight: bold;
    font-size: 24px;
    line-height: 53px;
  }
  
  /* .section.about .play-icon {
    margin-top: 76px;
    margin-bottom: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .section.about .play-icon span {
    font-size: 24px;
    line-height: 53px;
    color: #FF4F61;
    font-weight: 600;
    margin-right: 30px;
  } */

  @media (max-width: 1024px){
    .section.about{
        display:flex;
        flex-direction: row-reverse;
        color: #1e1e1e;
        justify-content: space-between;
        max-height: 100vh;
        margin-top: -240px;

    }
    .section.about .image-container{
        max-height: 100%;
        width: 35%;
        overflow: hidden;
    }

    .section.about .about-title{
        max-height: 100%;
        width: 65%;
        overflow: hidden;
    }
}
  @media (max-width: 769px) {
    .section.about{
        margin-top: -100px;
        flex-direction: column-reverse;
      }
      
      .section.about .image-container{
        width: 100%;
      }
}
  @media (max-width: 541px) {
    
    .section.about{
        margin-top: 20px;
        justify-content: center;
        min-height: 100vh;
      }
      .section.about .about-title{
        height: auto;
        width: 100%;
        margin-bottom: 70px;
      }

      .section.about .image-container img{
        width: 100%;
        height: auto;
        align-self: flex-start;
      }


      .section.about h2{
        font-size: 28px!important;
      }
      .section.about h4{
        font-size: 20px!important;
        line-height: 29px;
      }
    
      .section.about p{
        font-size: 18px!important;
        line-height: 29px;
      }
}
@media (max-width: 414px) {
  .section.about{
      height: auto;
      min-height: 0 !important;
  }
  .section.about .image-container{
      display: none;
    }
  
    .section.about .about-title{
      width: 100%;
      align-self: flex-start !important;
      margin: 0;
    }
}


  @media (max-width: 376px) {
    .section.about{
        height: auto;
    }
    .section.about .image-container{
        display: none;
      }
    
      .section.about .about-title{
        width: 100%;
        align-self: flex-start !important;
        margin: 0;
      }
}

