*{
  box-sizing: border-box;
}
.background-middle-section{
    background-size: cover;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    background-repeat: no-repeat;
    flex-direction: column;
    margin-top: 5%;
    margin: 0 !important;
    padding: 0 10%;
    min-height: 80vh;
  }
  
  .background-middle-section h1{
    color:#1e1e1e;
    font-size: 130px;
    margin: 0!important;
  }
  
  
    @media (max-width: 1281px){
      .background-middle-section{
        background-size: cover;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
        background-repeat: repeat;
        flex-direction: column;
        margin-top: 5%;
        /* margin: 30 !important; */
        padding: 0 10%;
      }
      
      .background-middle-section h1{
        color:#1e1e1e;
        font-size: 130px;
        margin: 0!important;
        
      }
      
  }
    @media (max-width: 1025px){
      .background-middle-section{
        min-height: 50vh;
      }
      .background-middle-section h1{
        color:#1e1e1e;
      }
  }
    @media (max-width: 769px) {
      .background-middle-section h1{
        color:#1e1e1e;
        font-size: 90px;
        margin: 0!important;
      }
  }
    @media (max-width: 541px) {

  }
    @media (max-width: 422px) {

  }
    @media (max-width: 376px) {

  }
  
  