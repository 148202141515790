body {
  margin: 0;
  padding: 0;
  font-family: 'Assistant', sans-serif;
  overflow-x
  : hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,a:link,a:hover,a:visited {
  text-decoration: inherit;
  color: #fff;
}

div{
  direction: rtl;
  text-align: right;
}

*{
  box-sizing: border-box;
}

.App{
  max-width: 99vw !important;
  overflow: hidden !important;
}

.red{
  color: red;
  margin: 10px 0;
}

.homeidv{
  max-height: 200vh;
  background-repeat: no-repeat;
  background-size: 100%;

}
.section{
  margin: 4% 10%;
  color: #fff;
} 

.page{
  margin: 4% 10%;
}

@media (max-width: 1024px){
  .page{
    margin-top: -240px;
  }
}
@media (max-width: 769px){
  .homeidv{
    background-image: none !important;
    background: linear-gradient(59deg, #FF6D67 0%, #FF4F61 100%) 0% 0% no-repeat padding-box !important;
    margin: 0!important;
  }
}

@media (max-width: 541px){
  .page{
    margin-top: 20px;
  }
}
@media (max-width: 412px){
  .page{
    margin-top: 70px;
  }
}
@media (max-width: 376px){
  .page{
    margin-top: 70px;
  }
}