.contact.section{
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-content: space-between;
    /* min-width: 100%; */
    /* width: 100%; */
    max-width: 100vw;
    max-height: 100vh;
    /* padding: 5%; */
    overflow: hidden;
  }
  
  .contact.image{
    /* max-width: 100%; */
    overflow: hidden;
    width: 60%;
  
  }
  .contact.image img{
    height:100%;
    min-width: 120%;
    width: auto;
  }
  
  .contact-form{
    width: 70%;
    padding-left: 4%;
  }
  
  .contact-form h2{
    font-size: 46px;
    line-height: 86px;
    color: #1e1e1e;
    font-weight: bold;
  }
  
  .contact-form h4{
    font-size: 16px;
    line-height: 20px;
    color: #292929;
  }
  
  .contact-form.form-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items:flex-end; */
    margin: 55px 0;
  }
  .contact-form.form-group input{
    color: #1e1e1e;
    display: inline-block;
    background: transparent;
    outline: none;
    width: 40%;
    border: 0;
    border-bottom: 1px solid #717171;
    height: 24px;
    font-size: 16px;
    font-family: 'Assistant', sans-serif;
  }
  .contact-form.form-group input::placeholder{
    color: #717171;
  }
  .contact-form.form-group.textarea{
    flex-direction: column;
  }
  .contact-form.form-group.textarea label{
    font-size: 16px;
    line-height: 24px;
    color: #717171;
  }
  
  .contact-form.form-group textarea{
    flex: 1;
    color: #717171;
    display: inline-block;
    background: transparent;
    outline: none;
    border: 1px solid #717171;
    width: 100%;
  }
  
  .contact-form.form-group.submit button{
    display: inline-block;
    padding: 18px 22px;
    color: #fff;
    background-color: #FF4F61;
    border-radius: 25px;
    font-size: 18px;
    line-height: 13.6px;
    font-weight: bold;
    width: 60%;
    border: 0;
  }
  .contact-form.form-group.submit button:hover{
    cursor: pointer;
    opacity: 0.9;
  }
  
    @media (max-width: 1281px){

        .contact.section{
            display: flex;
            flex-direction: row;
            text-align: right;
            justify-content: space-between;
            max-width: 100vw;
            max-height: 80vh;
            overflow: hidden;
          }
  }
    @media (max-width: 1024px){

  }
    @media (max-width: 769px) {

  }
    @media (max-width: 541px) {
      .contact-form h2{
        font-size: 28px;
      }
      .contact-form h4{
        font-size: 16px;
      }
        .contact.section .contact-form{
            min-width: 70vw;
          }
        
          .contact.section .contact.image{
            display: none;
          }
  }
    @media (max-width: 412px) {
        .contact.section .contact-form{
            min-width: 70vw;
          }
        
          .contact.section{
            min-height: 100vh !important;
          }

          
  }
    @media (max-width: 376px) {

  }
  
  