.section.logo{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: inherit;
    margin: 10% 5% 10% 5%;
  }
  .section.logo img{
    max-height: 60vh;
    max-width: 40vw;
    /* margin-right: 4%; */
  }
  
  .section.logo .logo-title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10%;
  }
  .section.logo h1{
    font-size: 100px !important;
    line-height: 0px;
  }
  .section.logo h4{
    font-size: 24px !important;
    line-height: 26px;
    margin: -60px 0;
  }
    @media (max-width: 1300px){
      .section.logo img{
        max-height: 30vh;
        max-width: 40vw;
        /* margin-right: 4%; */
      }
    
      .section.logo h1{
        font-size: 80px !important;
        /* line-height: 0px; */
      }

  }
    @media (max-width: 1024px){
      
  }
    @media (max-width: 769px) {
      .section.logo img{
        max-height: 30vh;
        max-width: 40vw;
        margin-right: 0%;
      }
    
      .section.logo h1{
        margin-top: 19%;
        font-size: 60px !important;
        /* line-height: 30px; */
      }
  }
    @media (max-width: 541px) {
      .section.logo{
        margin-top: 15%;
      }
      .section.logo img{
        max-height: 30vh;
        max-width: 40vw;
        margin-right: 0%;
      }
    
      .section.logo h1{
        /* margin-top: 19%; */
        font-size: 40px !important;
        line-height: 30px;
      }
  }
    @media (max-width: 415px) {
      .section.logo{
        margin-top: 25%;
        padding: 0 5%;
      }
      .section.logo img{
        max-height: 25vh;
        max-width: 40vw;
        margin-right: 0%;
      }
    
      .section.logo h1{
        /* margin-top: 19%; */
        font-size: 22px !important;
        line-height: 20px;
      }
  }

  