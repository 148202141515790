.section.join{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #fff;
    border-radius: 15%;
  }
  
  .section.join .join-title{
    color: #1e1e1e;
    font-size: 46px;
    line-height: 86px;
    font-weight: bold;
  }
  
  .section.join .join-boxes{
    display:flex;
    flex-direction: row;
    justify-content: stretch;
    width: 100%;
    max-height: 70vh;
  }
  
  .section.join .box{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 60px #8D8D8D29;
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    color: #1e1e1e;
    max-height: 90vh;
    overflow: hidden;
  }
  
  .section.join .box .box-image{
    height: 100%;
    width: 98%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section.join .box .box-image img{
    height: 170%;
    width: 100%;
    min-width: 100%;
    -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
    filter: grayscale(50%);
  }

  .box-title{
    font-size: 34px;
    line-height: 28px;
    position: absolute;
    top: 90px;
    color: #fff;
  }

  .section.join .box .box-link{
    position: absolute;
    bottom: 110px;
  }
  
  .section.join .box h3{
    font-size: 34px;
    line-height: 28px;
  }
  .section.join .box a:active,
  .section.join .box a:link,
  .section.join .box a:visited{
    font-size: 26px;
    color: #FF4F61;
    border-bottom: 1px solid #FF4F61;
  }

    @media (max-width: 1024px){

    .section.join{
    margin-bottom: 15%;
    }
    
  }
    @media (max-width: 769px) {
          .section.join .box-title h3,
          .section.join .box-link h3 a{
            font-size: 22px;
          }
  }
    @media (max-width: 541px) {
      .join-title p{
        font-size: 26px;
      }
      .box-title{
        top: 20px;
        font-size: 16px;
      }

      .section.join .box a:active,
      .section.join .box a:link,
      .section.join .box a:visited{
        font-size: 16px;
      }

      .section.join .box h3{
        font-size: 16px;
        line-height: 28px;
      }
    
      .section.join .box .box-link{
        position: absolute;
        bottom: 20px;
      }
  }
  
  