.header-section{
    position: absolute;
    display: flex;
    width: 100vw;
    justify-content: space-between;
    padding:  2% 4%;
    padding: 4% 10%;
    max-width: 100vw !important;
    overflow: hidden;
  }
  
.header-section img{
  height: 40px;
  }
  
  .header-section .btn-contact{
    color: white;
    font-size: 16px;
    line-height: 23px;
    background-color: #1e1e1e;
    font-weight: 600;
    border-radius: 40px;
    border: 0;
    padding: 5px 15px;
  }
  
    @media (max-width: 1281px){
    .header-section{
        align-items: flex-start;
    }
  }
    @media (max-width: 1024px){
        .header-section{
            align-items: flex-start;
          }
  }
    @media (max-width: 769px) {
        .header-section{
            align-items: flex-start;
          }
  }
    @media (max-width: 541px) {
        .header-section{
            align-items: flex-start;
        }
  }
    @media (max-width: 412px) {

  }
    @media (max-width: 376px) {
        .header-section img{
            height: 30px;
            width: auto;
          }
          .header-section button{
            padding: 4% !important;
          }
  }
  
  