.contact-form.form-group.cols{
    display: block;
}
.contact-form.form-group.col.radio{
    width: 80%;
}
.radio-row{
    max-width: 50%;
    display: flex;
}

.radio-span{
    display: inline-block;
    width: 0px;
    color:black !important;
}
.contact-form.form-group.col.radio input{
    max-width: 0px !important;
}
.contact-form.form-group.cols input{
    width: auto !important;
}
.contact-form.form-group.col{
    flex-direction: column;
    margin: 0 !important;
}
.contact-form.form-group.col input{
    margin: 10px 0;
    min-width: 80%;
}
.contact-form.form-group.col span{
color: red ;
margin: 10px 0;
}
.contact-form.form-group.col select{
    max-width: 80%;
    margin: 30px 0;
    height: 60px;
    border-radius: 10px;
    padding: 0 10px;
}

.form-desc{
    max-width: 80vw;
    padding-left: 0 !important;
}

.delivery.page p{
    margin-bottom: 60px;
}

.delivery.page 

.btn.btn-submit{
    display: inline-block;
    padding: 18px 22px;
    color: #fff;
    background-color: #FF4F61;
    border-radius: 25px;
    font-size: 18px;
    line-height: 13.6px;
    font-weight: bold;
    width: 60%;
    border: 0;
}
.btn.btn-submit:hover{
    cursor: pointer;
    opacity: 0.9;
  }