.footer-section{
  border: 1px solid var(--unnamed-color-707070);
  background: linear-gradient(59deg, #FF6D67 0%, #FF4F61 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  min-height: 80vh;
  z-index: -1;
}

.footer-background{
  background-repeat: no-repeat;
  background-size: 90% 145%;
  background-position: -95% -10%;
  min-height: 80vh;
  min-width: 100%;
}

.footer-logo{
  padding: 5% 10%;
}
.footer-logo img{
  height: 50px;
  width: auto;
}

.footer-logo h2{
  color: #fff;
  font-size: 46px;
  line-height: 26px;
  font-weight: bold;
}

.footer-logo .description{
  color: #fff;
  font-size: 24px;
  line-height: 44px;
  display: inline-block;
  max-width: 40%;
}

.footer-logo .store-icons{
  display: inline-flex;
  flex-direction: column;
  min-width: 25%;
  /* justify-content: space-between; */
}

.footer-logo .media-icons{
  display: inline-flex;
  flex-direction: row;
  min-width: 8%;
  justify-content: space-between;
  margin-top: 5%;
}
.footer-logo .media-icons img{
  height: 35px;
  width: auto;
}

.footer-logo .footer-links{
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 20%;
  color: #fff;
  font-size: 24px;
  line-height: 44px;
  text-decoration: underline;
}

  @media (max-width: 1281px){
    .footer-background{
      background-repeat: no-repeat;
      background-size: 90% 145%;
      background-position: -185% -10%;
    }
    
    .footer-logo .description{
      color: #fff;
      font-size: 24px;
      line-height: 44px;
      display: inline-block;
      max-width: 60%;
    
    }
    
    .footer-logo .store-icons{
      min-width: 40vw;
    }
    
    .footer-logo .media-icons{
      min-width: 13%;
    }
}
  @media (max-width: 1024px){

    .footer-logo .store-icons{
      min-width: 40% !important;
    }
  
    .footer-logo .footer-links{
      min-width: 34% !important;
    }
}
  @media (max-width: 769px) {

    .footer-logo .store-icons{
      flex-direction: column;
    }
    .footer-logo .media-icons{
      margin-top: 40px;
    }
  
    .footer-logo .footer-links{
      flex-direction: column;
    }
}
  @media (max-width: 541px) {
    .footer-background{
      background-size: 90% 120%;
      background-position: -205% -10%;
    }
    .footer-logo .media-icons img{
      margin-left: 10px;

    }

    .footer-logo h2{
      color: #fff;
      font-size: 32px;
      line-height: 26px;
      font-weight: bold;
    }
    .footer-logo p{
      min-width: 60vw;
    }
    
    .footer-logo .footer-links{

    }

    .footer-section .description{
      width: 100%!important;
      font-size: 28px;
      line-height: 38px;
    }
}
  @media (max-width: 412px) {
    .footer-section h2{
      line-height: 40px;
      font-size: 34px;
    }
  
    .footer-section p.description{
      min-width: 80vw !important;
      font-size: 22px;
    }
}
  @media (max-width: 376px) {
    .footer-section h2{
      line-height: 40px;
      font-size: 34px;
    }
  
    .footer-section p.description{
      min-width: 80vw !important;
      font-size: 22px;
    }
}

